<template>
  <div>
    <eden-page-stats
      class="mt-0"
      :title="'Overview'"
      :loading="loading"
      :stats="stats"
      :show-periods="false"
    >
      <template slot="actions">
        <eden-periods
          class="mr-10"
          :loading="loading"
          :default-period="'today'"
          :show-custom-period="true"
          :custom-title="'Orders for'"
          @set-period="getProvisionedOrders"
        />
        <eden-service-partners
          :loading="loading"
          :allow-all="true"
          :service-partner-name.sync="servicePartnerName"
          :service-partner-id.sync="servicePartnerId"
          :service="'meal'"
        />
      </template>
    </eden-page-stats>
    <provisioned-orders-view
      :loading="loading"
      :service="'meal'"
      :period="periodParams.period"
      :orders="setProvisionedOrders"
      :orders-time="ordersTime"
      @confirmed="getProvisionedOrders({ period: periodParams.period })"
      @filter="filter"
    />
  </div>
</template>

<script>
import ProvisionedOrdersView from "@/components/ServiceOperations/ProvisionedOrders/ProvisionedOrdersView";

import * as actions from "@/store/action-types";
import { parseProvisionedOrders } from "../provisioned-orders.config";

export default {
  name: "ProvisionedOrdersMeal",
  components: {
    ProvisionedOrdersView,
  },
  data() {
    return {
      loading: false,
      periodParams: {
        period: "today",
        from: null,
        to: null,
      },
      periods: [
        "lastweek",
        "yesterday",
        "today",
        "tomorrow",
        "nexttwodays",
        "thisweek",
        "nextweek",
      ],
      custom: {
        from: null,
        to: null,
      },
      servicePartnerName: "all",
      servicePartnerId: "all",
      provisionedOrders: {
        all: {},
      },
      ordersTime: "",
      confirmedOrders: [],
    };
  },
  computed: {
    setProvisionedOrders() {
      return this.provisionedOrders[this.servicePartnerName].orders;
    },
    stats() {
      const {
        customers,
        orders,
        meals,
        juices,
        confirmed,
        edited,
        incomplete,
      } = this.provisionedOrders[this.servicePartnerName];

      return [
        {
          label: "Total customers",
          figure: (customers && customers.length) || "-",
        },
        {
          label: "Total orders",
          figure: (orders && orders.length) || "-",
        },
        {
          label: "Total meals",
          figure: meals || "-",
        },
        {
          label: "Total juices",
          figure: juices || "-",
        },
        {
          label: "Orders confirmed",
          figure: confirmed >= 0 ? confirmed : "-",
        },
        {
          label: "Orders edited",
          figure: edited >= 0 ? edited : "-",
        },
        {
          label: "Orders  incomplete",
          figure: incomplete || "-",
        },
      ];
    },
  },
  created() {
    this.getProvisionedOrders({
      period: this.periodParams.period,
      from: this.custom.from,
      to: this.custom.to,
    });
  },
  methods: {
    getProvisionedOrders({ period, from, to, menu_type, customer_type }) {
      console.log( period, from, to);
      this.loading = true;
      this.period = period;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
        from,
        to,
      });
      this.$store
        .dispatch(actions.GET_PROVISIONED_ORDERS, {
          service: "meal",
          start_date,
          end_date,
          menu_type,
          customer_type,
        })
        .then((response) => {
          const { status, data } = response.data;

          console.log("djjdd", data);
          if (status) {
            
            this.provisionedOrders = parseProvisionedOrders(
              data,
              "meal",
              this.confirmedOrders,
            );
            this.ordersTime = new Date();
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    filter(val) {
      const parts = val.split("-");

      this.getProvisionedOrders({
        period: this.periodParams.period,
        from: this.periodParams.from,
        to: this.periodParams.to,
        menu_type: parts.length > 1 ? parts[1] : val,
        customer_type: parts.length > 1 ? parts[0] : null,
      });
    },
  },
};
</script>
